<template>
  <div>
    <transition name="van-slide-left">
      <div v-show="visible">
        <van-nav-bar
            title="我的"
        />
        <van-cell-group inset style="margin-top: 10px">
          <van-cell title="昵称" :value="user.petName"/>
          <van-cell title="实名" :value="handeleStr(user.realName)"/>
          <van-cell title="手机号" :value="test(user.mobile)"/>
        </van-cell-group>
        <div style="margin: 20px">
          <van-button type="default" block round @click="logout" :loading="loginLoading" loading-text="正在退出...">退出登录</van-button>
        </div>
      </div>
    </transition>
    <bottom-bar/>
  </div>
</template>

<script>
import BottomBar from "@/components/BottomBar";
import VantMessage from "@/utils/vantMessageUtils";
import {remove} from "@/utils/authUtils";
import {getUserById} from "@/api/user/user";

export default {
  name: "myData",
  data() {
    return {
      loginLoading:false,
      visible: false,
      user:[],
    }
  },
  components: {
    BottomBar
  },
  methods:{
    logout() {
      VantMessage.confirmMsg("你确定要退出登录吗？").then(resolve => {
        if (resolve) {
          this.loginLoading = true;
          remove();
          setTimeout( () => {
            this.$router.replace("/login")
          }, 2000);
        }
      })
    },
    async getUserById() {
      let res = await getUserById(null);
      if (res) {
        this.user = res;
      }
    },
    handeleStr(str) {
      if (str) {
        let length = str.length
        let newArr = str.split('');
        newArr[str.length - 1] = '*';
        return newArr.join('');
      }
    },
    test(tel) {
      if (tel) {
        return tel.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")

      }
    }
  },
  mounted() {
    this.visible = true;
    this.getUserById()
    document.title = this.$route.meta.title
  }
}
</script>

<style scoped>

</style>
